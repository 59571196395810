
// import soon from '../assets/images/coming-soon.svg'
// import blockchain from '../assets/images/kiwi.svg'
import bridge from '../assets/images/bridgecard.svg'
import staple from '../assets/images/staple.svg'
// import ferry from '../assets/images/ferry.svg'
import marble from '../assets/images/marble.svg'
import asteria from '../assets/images/asteria.png'


export const PROJECTS = [

   // {
   //    id: 1,
   //    project: "Ferry Suite",
   //    image: ferry,
   //    // link: "/ferry",
   //    title: "Ferry suite",
   //    subtitle: "Business management on autopilot",
   //    lead: "COMING SOON"
   // },
   {
      id: 2,
      project: "Bridgecard",
      image: bridge,
      link: "/bridgecard",
      title: "Bridgecard",
      subtitle: "One card to manage finances and payments",
      lead: "Read Case Study"
   },
   // {
   //    id: 3,
   //    project: "KIWI",
   //    image: blockchain,
   //    link: "/kiwi",
   //    title: "KIWI",
   //    subtitle: "A smarter way to invest in crypto",
   //    lead: "Read Case Study"
   // },
   {
      id: 4,
      project: "Staple",
      image: staple,
      link: "/staple",
      title: "Staple",
      subtitle: "Helping businesses track revenue and subscriptions",
      lead: "Read Case Study"
   },
   {
      id: 5,
      project: "Marble Wallet",
      image: marble,
      link: "/marble-wallet",
      title: "Marble Wallet",
      subtitle: "Simplifying crytocurrency trading",
      lead: "Read Case Study"
   },
   {
      id: 6,
      project: "Asteria",
      image: asteria,
      link: "/asteria",
      title: "Asteria",
      subtitle: "Automated Asset Management System",
      lead: "Read Case Study"
   },
]